.rojmel-table {
    width: 800px;
    height: 200px;
    border-collapse: collapse;
    margin: auto;
}

.rojmel-table th {
    border-bottom: 1px solid black;
    border: 2px solid black;
}

.rojmel-table td {
    text-align: center;
    border: 2px solid black;
}